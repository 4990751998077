@import "~ant-design-vue/lib/style/themes/index";

// FIXME: Customizing antd less variables here somehow has no effect, even though in principle the last definition in scope takes effect, so this should override ant defaults
// Instead, we use `modifyVars` in `vue.config.js`, although changes there cannot be hot-reloaded.

// NOTE: As of Nov 10 2023 we no longer override layout-body-background, as white background everywhere really doesn't look good.
//@layout-body-background: #FFFFFF; // used to be #f0f2f5; we want components like <ImpFileView> to look the same at top-level (e.g. from <ImpFileRoute>) and in pop-ups.

//@form-item-margin-bottom: 0; // used to be 24px; this makes forms too spacy, especially EditMatchView.
//@tooltip-max-width: 600px; // The default is 250px, but this is too narrow for our long tooltips in e.g. Export output selection

// In the built-in browser of MIUI14, if 网页浏览设置/左右滑屏手势 is enabled, we are unable to scroll e.g. the audit log table and the edit match dialog box, and easily trigger the history backward action.
// * On the built-in browser of MIUI11, scrolling is possible, but the backward action is still easily triggered.
// * Setting `overscroll-behavior-x: none;` doesn't seem to have any effect.  Instead, we have to disable 左右滑屏手势.
// * See <https://blog.csdn.net/zuijiao__shangyang/article/details/88837357> for another possible solution.
// Chrome, either the desktop version in the mobile emulation or the Android version 120, appears to be working fine without the need for `overscroll-behavior-x` settings.
// * On Chrome for Android 120, the "overscroll history navigation" flag in `chrome://flags` is no longer available.
// * On Desktop Chrome, overscroll history navigation can be explicitly enabled in `chrome://flags`.
//   When enabled, it takes effect in e.g. `google.com` in mobile emulation mode, but it is necessary to swipe quite far for this to take effect, and a "back" icon is shown when swiping that far.  It does not take effect at all on GDSDX, even without any `overscroll-behavior-x` settings.
//html, body { overscroll-behavior-x: none; }

// See the comments in <MatchesView>; this is somehow necessary to make horizontal scrollbars show up when we have an overly wide table.
.ant-modal-body, .ant-tabs-tabpane, .ant-table-content { overflow-y: auto; /*overscroll-behavior-x: none;*/ }

// The action list of Receive jobs can be quite wide if there are a lot of output files.  If we can't have line wrapping, then a scroll bar is at least required.
.ant-card-actions { overflow: auto; }

// Overly narrow ant-input's are not usable at all.  This is a failsafe; in general the containing dialog should have proper min-width settings
.ant-input { min-width: 60px; }

// Tables with black internal borders; used in formal situations or when rowspan/colspan makes the table hard to read at default border settings.
// This is difficult to implement with scoped CSS due to the need to override the .ant-table-row:last-child rules; Vue's scoped CSS does not appear able to mix :global() parts and local parts in the same selector.
.gdsdx-table-b {
  .ant-table, &.ant-table { // The original selectors are preserved, and .gdsdx-table-b is added, so that the specificity is always higher
    table { border-collapse: collapse; } // the default in ant-table is "separate", which would make the internal borders twice as wide
    .bordered { border-top: 1px solid #000; border-bottom: 1px solid #000; }
    th, td { .bordered(); }
    &.ant-table-small > .ant-table-content .ant-table-row:last-child {
      th, td { .bordered(); }
    }
  }
}

thead.ant-table-thead > tr > th { font-weight: bold; }

// Here the intent is to rely on higher specificity of `.ant-input.ant-input-lg` to ensure that font-size is overridden; however, even without this, overriding works due to later CSS rules having priority.
//
// However, This does not solve the problem that the username/password fields in the login screen shows with a smaller font in Chrome, instead of the intended 16px font for .ant-input-lg.
// We see that the CSS has been loaded before the username <input> is rendered, but its font size still fails to get updated until the user focuses on it.
// <https://stackoverflow.com/questions/57629352/font-size-changing-upon-page-focus-or-field-focus> may be relevant.
// Indeed, the problem only appears for autofilled content (e.g. username/password filled by the browser), not anything filled by JavaScript code.
// Autofilled content has distinct styles specified via the `:webkit-autofill` selector, e.g. blue background.  However, attempting to set font size still doesn't work until the input box
// is clicked on (even though `:webkit-autofill` is applicable both before and after clicking, as long as no edit is done).
//
// According to <https://bugs.chromium.org/p/chromium/issues/detail?id=960918#c26>, the reason for such behavior seems to be security; the browser does not want the JavaScript code to see the autofilled data
// before the user actually clicks the login button or edits the autofilled text, and custom fonts can leak information if different characters have different metrics.  The autofilled data actually
// resides in a "shadow DOM".  <https://chromium.googlesource.com/chromium/src/+/9e68c4db781b71e9ea772ab60d34c7f10e100b57%5E%21/#F1> appears to be the patch that forces the font choice.
//
// @import 'ant-design-vue/lib/input/style/mixin.less';
// .ant-input { // NOTE: There are still some .ant-input-affix-wrapper rules that may accidentally get overwritten, leading to padding issues
//   &&-lg { 
//     &, &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus {
//       .input-lg() !important;
//     }
//   }
//   &&-sm { .input-sm(); }
// }

// Should have the same effect as <code>; used when we don't want to insert additional stuff into the DOM
.gdsdx-code { font-family: monospace; }

// Setting `className="gdsdx-code-col"` causes the entire column to have this class (in addition to other class names; see the call to `classNames()` in `ant-design-vue/es/vc-table/src/TableCell.js`.
// We don't want the table header to be affected, thus the td.
td.gdsdx-code-col { font-family: monospace; }

// Width settings of various pop-up dialog boxes
// NOTE: Setting `width: 90%` in CSS won't take effect, as it will be overridden by the width prop of the `<a-modal>` component, even if it is not explicitly specified.  Instead, we need to set `width="90%"` in the `<a-modal>` component.
.gdsdx-modal { min-width: 600px; }
.gdsdx-modal-edit-match { .gdsdx-modal(); min-width: 800px; } // at 600px the asset IDs are truncated, looking ugly
.gdsdx-modal-file { .gdsdx-modal(); } // a bit awkward when displaying the match table, since there could exist two levels of scroll bars.  However, it remains usable.
.gdsdx-modal-import { .gdsdx-modal(); }
.gdsdx-modal-acc { .gdsdx-modal(); min-width: 800px; }
.gdsdx-modal-fl-cfg { .gdsdx-modal(); }

.gdsdx-hflex-container { display: flex; align-items: center; }
.gdsdx-hflex { flex: 1; }
.gdsdx-label { margin-right: 0.5em; }
.gdsdx-card-label { .gdsdx-label(); }
.gdsdx-item-label { .gdsdx-label(); font-style: italic; }

h1.gdsdx-report-title { font-size: 1.33em; font-weight: bold }

// Sometimes (e.g. when allowClear=true), input.ant-input becomes a child element of the <a-input> element where .gdsdx-bg-* is applied to.
// Hence, we need to match .ant-input that is either the same element as .gdsdx-bg-custom, or is a descendent.
// NOTE: We also wanted to use @alert-info-bg-color, etc., but they are too light when we are encountering errors
.gdsdx-bg-custom { &.ant-input, .ant-input { background-color: @green-2 } } // e.g. customized society codes
.gdsdx-bg-dirty { &.ant-input, .ant-input { background-color: @gold-2 } } // edited stuff that has yet to be synchronized to parent components
.gdsdx-bg-err { &.ant-input, .ant-input { background-color: @red-3 } } // edited stuff that cannot be parsed

.gdsdx-absent { color: @input-placeholder-color } // for placeholders where no item yet exists but can be added
.gdsdx-clist-citem { position: relative } // can be used in both gdsdx-clist and gdsdx-elist

.gdsdx-clist-cbox-def(@indent) {
  .gdsdx-clist-cbox { position: absolute; left: -@indent; width: @indent; text-align: right; padding-right: 0.5em }
}

@gdsdx-elist-e-indent: 60px;
.gdsdx-elist {
  // The `position: relative` just establishes a reference for absolute positioning of its descendents; it seems better to attach the buttons to the <li> instead of the <ul>.
  > li { position: relative; }
  &.gdsdx-elist-ne-plain { list-style-type: none; padding-left: 0px; }
  &.gdsdx-elist-e {
    padding-left: @gdsdx-elist-e-indent; // indentation is only needed when editable to make space for the insert/delete buttons
    > li { // this also includes .gdsdx-clist-citem, which is always a <li>
      list-style-type: none;
      // `left` is w.r.t. the left edge of the containing block (<https://developer.mozilla.org/en-US/docs/Web/CSS/Containing_block>), which is the nearest element with non-static `position`, i.e. the `.gdsdx-elist-ul > li`.
      .gdsdx-elist-btns { position: absolute; left: -@gdsdx-elist-e-indent } // Had we written `right: 0`, my right edge would have been relative to the right edge of the <li>, not its left edge.
      .gdsdx-elist-placeholder { font-style: italic; color: @input-placeholder-color }
      .gdsdx-clist-cbox-def(@gdsdx-elist-e-indent);
    }
  }
}

@gdsdx-clist-e-indent: 40px; // same as the default in chrome; only applicable when editable
.gdsdx-clist {
  &.gdsdx-clist-e { // when the clist is editable
    padding-left: @gdsdx-clist-e-indent;
    > .gdsdx-clist-citem {
      list-style-type: none;
      .gdsdx-clist-cbox-def(@gdsdx-clist-e-indent);
    }
  }
}

.gdsdx-origin-derived { text-decoration: underline }