<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view/> <!-- This is the top-level page above the login page, so we should not use VerifiedRouterView here -->
    </div>
  </a-config-provider>
</template>
<script>
  import enUS from 'ant-design-vue/lib/locale-provider/en_US'
  import enquireScreen from '@/utils/device'

  export default {
    data () {
      return {
        locale: enUS,
      }
    },
    created () {
      let that = this
      enquireScreen(deviceType => {
        // tablet
        if (deviceType === 0) {
          that.$store.commit('TOGGLE_DEVICE', 'mobile')
          that.$store.dispatch('setSidebar', false)
        }
        // mobile
        else if (deviceType === 1) {
          that.$store.commit('TOGGLE_DEVICE', 'mobile')
          that.$store.dispatch('setSidebar', false)
        }
        else {
          that.$store.commit('TOGGLE_DEVICE', 'desktop')
          that.$store.dispatch('setSidebar', true)
        }

      })
    }
  }
</script>
<style>
  #app {
    height: 100%;
  }
</style>